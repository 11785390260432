import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Overview from "../views/Overview.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "overview",
    component: Overview
  },
  {
    path: "/artworks/:slug",
    name: "overview-detail",
    component: Overview
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if(to.name == 'overview-detail' || (to.name == 'overview' && from.name != 'about')) {
      return null;
    }
    return { x: 0, y: 0 };
  }
});

export default router;
