<template>
  <div :class="{ 'filter':true, 'showmobile':showMobile }">
    <a href="#" v-if="activeFilter != null" @click.prevent="clearFilter()" class="clearfilter">{{ $t('showAll') }}</a>
    <ul class="filter__list">
      <li v-for="cat in categories" :key="cat.id" :class="{ 'active': isActiveFilter(cat.id) }">
        <a href="#" @click.prevent="setFilter(cat)">{{ cat.title }}</a>
      </li>
    </ul>
    <button class="mobile-toggle" :class="{ 'active':activeFilter!=null }" @click="showMobile = !showMobile">{{ mobileToggleTitle }}</button>
  </div>
</template>

<script>
export default {
  name: 'OverviewFilter',
  data: () => {
    return {
      showMobile: false
    }
  },
  watch: {
    activeFilter() {
      this.showMobile = false;
    }
  },
  mounted() {

  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    activeFilter() {
      return this.$store.state.activeFilter;
    },
    mobileToggleTitle() {
      if(!this.activeFilter) return 'Werktypen';
      return this.activeFilter.title;
    }
  },
  methods: {
    setFilter(cat) {
      this.$store.commit('setActiveFilter', cat);
    },
    clearFilter(cat) {
      this.$store.commit('setActiveFilter', null);
    },
    isActiveFilter(id) {
      if(!this.activeFilter) return false;
      return this.activeFilter.id == id;
    }
  }
}
</script>

<style scoped lang="scss">

@import '../scss/helpers';

.filter {
  position: fixed;
  bottom: var(--gutter);
  right: var(--gutter);
  text-align: right;
  z-index: 4;
}
.filter__list {
  text-align: right;

  li {
    line-height: .9;
  }
  a {
    font-size: .7rem;
  }
  li.active {
    a {
      font-style: italic;
    }
  }
}
.clearfilter {
  display: inline-block;
  font-size: .7rem;
  margin-bottom: 1rem;
}
.mobile-toggle {
  display: none;
}

@include bp-s() {
  .filter {
    z-index: 600;
    bottom: 2rem;
    right: 0;
    width: 100%;
    height: auto;
    background: #fff;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -2rem;
      left: 0;
      width: 100%;
      height: 2rem;
      background: linear-gradient(to bottom, rgba(#fff, 0), #fff);
      opacity: 0;
      pointer-events: none;
      transition: .5s opacity;
    }
    &.showmobile::before {
      opacity: 1;
    }
  }
  .filter__list {
    display: block;
    background-color: #fff;
    width: 100%;
    padding-right: var(--gutter);
    padding-bottom: 1rem;
    max-height: 0;
    overflow: hidden;
    padding-bottom: 0;
    font-size: 10px;
    transition: .5s max-height;

    .showmobile & {
      max-height: 300px;
    }
    li {
      padding: .2rem 0;
    }
    a {
      font-size: .7rem;
      font-size: 14px;
    }
  }
  .mobile-toggle {
    display: block;
    position: absolute;
    bottom: -2rem;
    line-height: 2rem;
    font-size: .7rem;
    border: none;
    background: transparent;
    --webkit-appearance: none;
    padding: 0;
    border-radius: 0;
    right: var(--gutter);

    &.active {
      font-style: italic;
    }
  }
  .clearfilter {
    display: none;
    padding-bottom: .75rem;
    margin-bottom: 0;
    padding-right: var(--gutter);

    .showmobile & {
      display: block;
    }
  }
}

</style>
