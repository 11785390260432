<template>
  <a
    class="digression"
    href="https://digression.archives-du-futur.com"
    target="_blank"
    @mouseout="handleMouseOut"
    @mouseover="handleMouseOver"
    ><span>{{ $t("digression") }}</span
    ><video
      :src="currentVideo.video"
      :poster="currentVideo.poster"
      ref="vidEl"
      muted
      loop
    ></video>
  </a>
</template>

<script>
export default {
  name: "Digression",
  data: () => {
    return {
      currentVideoIndex: 0,
      videos: [],
    };
  },
  mounted() {
    for (let i = 1; i <= 5; i++) {
      this.videos.push({
        video: "/digression/0" + i + ".mp4",
        poster: "/digression/0" + i + ".jpg",
      });
    }
    this.currentVideoIndex = ~~(Math.random() * this.videos.length);
  },
  computed: {
    currentVideo() {
      if (!this.videos.length) return { video: "", poster: "" };
      return this.videos[this.currentVideoIndex];
    },
  },
  methods: {
    handleMouseOut() {
      this.$refs.vidEl.pause();
      this.currentVideoIndex++;
      this.currentVideoIndex %= this.videos.length;
    },
    handleMouseOver() {
      this.$refs.vidEl.play();
    },
    randomItemFromArray(array) {
      return array[Math.floor(Math.random() * array.length)];
    },
    isNumberOrFloat() {
      return /^\d+\.?\d*$/.test(this.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/helpers";

.digression {
  $buttonsize: 5rem;

  position: fixed;
  z-index: 5;
  display: block;
  width: $buttonsize;
  height: $buttonsize;
  top: 4.8rem;
  right: var(--gutter);
  border-radius: 99%;
  background-color: #fff;
  background-image: url("../assets/digression-button.png");
  background-size: 100%;
  background-position: center;
  text-align: center;
  line-height: $buttonsize;
  overflow: hidden;
  font-size: 0.7rem;
  transform: translate3d(0, 0, 0);

  @include bp-s() {
    display: none;
  }

  &:hover {
    color: #fff;
    // background: #000;

    video {
      opacity: 1;
    }
  }
  span {
    position: relative;
    z-index: 1;
    pointer-events: none;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
  }
}
</style>
