import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeLang: "de",
    langs: ["de", "fr", "en"],
    ios: false,
    apiUrl: null,
    workCycles: null,
    categories: null,
    activeFilter: null,
    aboutData: null,
    showMobileFilter: false,
    sphereTexture: null,
    sphereVisible: false,
    iosTouched: null
  },
  mutations: {
    setLanguage(state, data) {
      state.activeLang = data;
    },
    setIos(state, data) {
      state.ios = data;
    },
    setIosTouched(state, data) {
      state.iosTouched = data;
    },
    setWorkCycles(state, data) {
      state.workCycles = data;
    },
    setCategories(state, data) {
      state.categories = data;
    },
    setActiveFilter(state, data) {
      state.activeFilter = data;
    },
    setApiUrl(state, data) {
      let url = process.env.VUE_APP_APIURL
        ? process.env.VUE_APP_APIURL
        : "https://cms.archivesdufutur.ch/";
      url += state.activeLang + "/api/";
      state.apiUrl = url;
    },
    setShowMobileFilter(state, data) {
      state.showMobileFilter = data;
    },
    setSphereTexture(state, data) {
      state.sphereTexture = data;
    },
    setSphereVisible(state, data) {
      state.sphereVisible = data;
    }
  },
  actions: {
    loadInitialData(context) {
      context.dispatch("loadWorkCycles");
      context.dispatch("loadCategories");
      context.dispatch("loadAboutData");
    },
    loadWorkCycles({ state, commit }) {
      axios
        .get(state.apiUrl + "artworks")
        .then(data => {
          state.workCycles = data.data.data;
        })
        .catch(error => {
          console.error("Failed fetching data");
        });
    },
    loadCategories({ state, commit }) {
      axios
        .get(state.apiUrl + "categories")
        .then(data => {
          state.categories = data.data.data;
        })
        .catch(error => {
          console.error("Failed fetching data");
        });
    },
    loadAboutData({ state, commit }) {
      axios
        .get(state.apiUrl + "about")
        .then(data => {
          state.aboutData = data.data.data;
        })
        .catch(error => {
          console.error("Failed fetching data");
        });
    },
    changeLanguage({ state, commit }, data) {
      window.localStorage.setItem("ADF_LANG", data.lang);
      commit("setLanguage", data.lang);
      data.i18n.locale = data.lang;
    }
  },
  getters: {
    filteredCycles: state => {
      if (!state.activeFilter) return state.workCycles;

      return state.workCycles.filter(cycle => {
        let re = false;
        cycle.categories.forEach(c => {
          if (c.id == state.activeFilter.id) {
            re = true;
          }
        });
        return re;
      });
    }
  }
});
