export default {
  de: {
    intro: 'works from 1973 to the present',
    detail: 'Detail',
    videoStill: 'Standbild',
    extract: 'Ausschnitt',
    showDetail: 'Details anzeigen',
    hideDetail: 'Details ausblenden',
    showAll: 'Alle anzeigen',
    dimensionsVariable: 'Format variabel',
    exhibitions: 'Ausstellung(en)',
    collections: 'Sammlung(en)',
    noDate: '(o.D.)',
    tobecontinued: 'wird fortgesetzt',
    digression: 'Exkurs',
    expositionLink: 'Ausstellung ‹Le tremblement des certitudes›, MCBA, 2023',
  },
  fr: {
    intro: 'works from 1973 to the present',
    detail: 'Détail',
    videoStill: 'Videostill',
    extract: 'Extrait',
    showDetail: 'Voir détails',
    hideDetail: 'Cacher détails',
    showAll: 'Voir tout',
    dimensionsVariable: 'dimensions variables',
    exhibitions: 'Exhibition(s)',
    collections: 'Collection(s)',
    noDate: '(s.d.)',
    tobecontinued: 'à suivre',
    digression: 'Digression',
    expositionLink: 'Éxposition ‹Le tremblement des certitudes›, MCBA, 2023',
  },
  en: {
    intro: 'works from 1973 to the present',
    detail: 'Detail',
    videoStill: 'Video still',
    extract: 'Extract',
    showDetail: 'Show details',
    hideDetail: 'Hide details',
    showAll: 'Show All',
    dimensionsVariable: 'dimensions variable',
    exhibitions: 'Exhibition(s)',
    collections: 'Collection(s)',
    noDate: '(n.d.)',
    tobecontinued: 'to be continued',
    digression: 'Digression',
    expositionLink: 'Exposition ‹Le tremblement des certitudes›, MCBA, 2023',
  },
};
