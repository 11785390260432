<template>
  <article class="cycle">
    <div class="mobile-meta">
      <span class="year" v-if="item.year">{{ item.year }}</span>
      <ul class="categories">
        <li v-for="cat in item.categories" :key="cat.id">{{ cat.title }}</li>
      </ul>
    </div>
    <div class="cycle__layout" ref="wrapper">
      <template v-for="block in cycleData.layout">
        <div
          v-if="block.type == 'artworkRow'"
          class="layout__row"
          v-bind:key="block.id"
          :style="getRowStyle(block)"
        >
          <figure
            v-for="visual in block.blocks"
            v-bind:key="visual.id"
            class="layout__image"
            :class="{
              empty: visual.asset == null,
              nometa: !visual.meta.title,
              'ios-active': iosTouched === visual.id,
            }"
            @touchstart="toggleIosTouched(visual.id)"
            :style="getBlockStyle(visual)"
          >
            <img
              v-if="visual.asset && visual.asset.type == 'image'"
              :src="getImgSrc(visual.asset)"
              :alt="visual.asset.title"
              :width="visual.asset.width"
              :height="visual.asset.height"
            />
            <VideoPlayer
              v-if="visual.asset && visual.asset.type == 'video'"
              :src="getImgSrc(visual.asset)"
              :hasAudio="visual.meta.videoHasAudio"
              :isInverted="visual.meta.whiteMuteText"
            />
            <AudioPlayer
              v-if="visual.asset && visual.asset.type == 'audio'"
              :src="getImgSrc(visual.asset)"
            />
            <div class="layout__placeholder" v-if="!visual.asset">
              <span>{{ visual.meta.missingText || "(manquant)" }}</span>
            </div>
            <figcaption v-if="visual.type == 'artwork' && visual.meta.title">
              <span class="caption-text"
                ><em>{{ visual.meta.inventaryId }}</em
                >{{ visual.meta.caption
                }}<span v-if="!visual.meta.year"> {{ $t("noDate") }}</span>
                <template v-if="visual.meta.amendment.length > 0"
                  > (<span
                    v-for="ame in visual.meta.amendment"
                    class="amendment"
                    :key="ame.value"
                    >{{ $t(ame.value) }}</span
                  >)
                </template>
              </span>
            </figcaption>
            <figcaption
              v-if="visual.type == 'customArtwork' && visual.meta.title"
            >
              <span class="caption-text"
                ><em v-if="visual.meta.inventaryId">{{
                  visual.meta.inventaryId
                }}</em
                >{{ visual.meta.title }}</span
              >
            </figcaption>
          </figure>
        </div>
        <div
          v-if="block.type == 'textBlock'"
          class="layout__text"
          :style="getTextBlockStyle(block)"
          v-bind:key="block.id"
        >
          <p>{{ block.text }}</p>
        </div>
      </template>
    </div>
  </article>
</template>

<script>
import axios from "axios";
import VideoPlayer from "./VideoPlayer";
import AudioPlayer from "./AudioPlayer";

export default {
  name: "CycleLayout",
  props: ["item", "cycleData"],
  data: () => {
    return {};
  },
  components: {
    VideoPlayer,
    AudioPlayer,
  },
  mounted() {},
  computed: {
    iosTouched() {
      return this.$store.state.iosTouched;
    },
    ios() {
      return this.$store.state.ios;
    },
  },
  methods: {
    getBlockStyle(img) {
      let st = {};
      st.width = img.properties.width ? img.properties.width + "%" : null;
      st.marginTop = img.properties.marginTop
        ? img.properties.marginTop + "%"
        : null;
      st.marginLeft = img.properties.marginLeft
        ? img.properties.marginLeft + "%"
        : null;
      return st;
    },
    getRowStyle(row) {
      let st = {};
      st.marginBottom = row.marginBottom ? row.marginBottom + "%" : null;
      return st;
    },
    getMetaStyle() {
      // return;
      const el = document.querySelector('.head.router-link-active .head__title');
      if(!el) return;
      console.log(el.getBoundingClientRect());
      let st = {};
      let p = el.getBoundingClientRect().height + 'px';
      console.log(p);
      st.paddingTop = p;
      return st;
    },
    getTextBlockStyle(row) {
      let st = {};
      st.margin = "13% 0 13% 30%";
      if (row.margins == "largeAbove") {
        st.margin = "18% 0 9% 30%";
      }
      if (row.margins == "largeBelow") {
        st.margin = "9% 0 18% 30%";
      }
      return st;
    },
    getImgSrc(asset) {
      if (!asset) return null;
      return asset.url;
    },
    toggleIosTouched(visualId) {
      if (!this.ios) return;
      if (this.iosTouched === visualId) {
        this.$store.commit("setIosTouched", null);
      } else {
        this.$store.commit("setIosTouched", visualId);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/helpers";

.cycle {
  padding: 0 0;
}

.cycle__layout {
  overflow: hidden;
  // padding-bottom: 10rem;
  // background: #fff;
  // transition: 1s height;
}
.layout {
  &__row {
    // margin: 7rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 13%;

    &:first-child {
      margin-top: 13%;
      // margin: 2rem 0 7rem 0;
    }
    &:last-child {
      margin-bottom: 13%;
    }
  }
  &__image {
    position: relative;

    img,
    video {
      display: block;
      width: 100%;
      height: auto;
    }
    figcaption {
      display: block;
      position: absolute;
      bottom: -0.12rem;
      left: 0;
      width: 100%;
      font-size: 0.7rem;
      opacity: 0;
      pointer-events: none;
      transition: 0.2s opacity;

      span.caption-text {
        display: block;
        position: relative;
        z-index: 1;
        padding-left: 5px;
        line-height: 1.2;
        white-space: pre-wrap;
      }
      em {
        font-style: normal;
        margin-right: 1rem;
      }
      &::after,
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
      }
      &::before {
        bottom: 0;
        height: calc(100% - 0.25rem);
        // background: linear-gradient(rgba(#fff, .8) 0%, rgba(#fff, 1) 100%);
        background: #fff;
      }
      &::after {
        bottom: calc(100% - 0.25rem);
        height: 2rem;
        @include scrimGradient(#fff, "to top");
      }
    }
    &.empty {
      width: 20%;

      figcaption {
        bottom: 0.1rem;
      }
    }
    &.empty figcaption::after,
    &.empty figcaption::before {
      background: none;
    }
    &.nometa:hover .layout__placeholder span {
      opacity: 1;
    }
    &.nometa:hover figcaption {
      opacity: 0;
    }
    .amendment {
      &::after {
        content: ", ";
      }
      &:last-child::after {
        content: "";
      }
    }

    .no-ios &:hover,
    &.ios-active {
      figcaption {
        opacity: 1;
      }
      .layout__placeholder span {
        opacity: 0;
      }
    }
  }
  &__text {
    width: 65%;
    // padding: 0 var(--gutter) !important;
    // margin-left: 0 !important;
    // margin-right: 0 !important;
    // margin: 6rem 0 6rem 30%;

    p {
      font-family: $f-secondary;
      font-size: 1.2rem;
      // font-size: 2.66vw;
      line-height: 1.12;
      white-space: pre-wrap;
    }
  }
  &__placeholder {
    height: 0;
    width: 100%;
    padding-bottom: 75%;
    border: 1px solid black;
    box-sizing: border-box;
    background: #fff;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.7rem;
      transition: 0.2s opacity;

      @include bp-s() {
        font-size: 4vw;
      }
    }
  }
}

.mobile-meta {
  display: none;
}

@include bp-s() {
  .cycle__layout {
    padding: 2rem 0;
  }
  .layout {
    &__row {
      margin: 0 !important;
    }
    &__image {
      width: 100% !important;
      margin-left: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0.5rem !important;
    }
    &__text {
      width: calc(100% - 1rem);
      margin-left: 0 !important;
      margin-left: 0 !important;
      padding: 2rem var(--gutter) !important;

      p {
        font-size: 1rem;
      }
    }
  }
  .mobile-meta {
    display: block;
    padding: 1rem 0 0 0;
    padding-left: calc(2.4rem + var(--gutter));
    font-size: 0.75rem;

    .year {
      display: block;
      margin-top: .5rem;
    }
    .categories {
      // margin-top: .5rem;

      li {
        display: inline;

        &::after {
          content: " / ";
        }
        &:last-child::after {
          content: "";
        }
      }
    }
  }
}
</style>
