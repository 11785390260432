<template>
  <div
    class="videoplayer"
    :class="{ active: isActive }"
    @touchstart="isActive = !isActive"
  >
    <button
      @click="isMuted = !isMuted"
      v-if="hasAudio"
      class="mutebtn"
      :class="{ 'mutebtn--active': isMuted, 'mutebtn--inverted': isInverted }"
    >
      <span>{{ btnText }}</span>
    </button>
    <video :src="src" loop autoplay playsinline :muted="isMuted"></video>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: ["src", "hasAudio", "isInverted"],
  data: () => {
    return {
      isActive: false,
      isMuted: true,
    };
  },
  components: {},
  mounted() {},
  computed: {
    btnText() {
      return this.isMuted ? "Audio off" : "Audio on";
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../scss/helpers";

.videoplayer {
  video {
    display: block;
    width: 100%;
    height: auto;
    pointer-events: none;
  }
  .mutebtn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    font-size: 0.7rem;
    border: none;
    border-radius: 0;
    background: transparent;
    background-image: url("../assets/sound-on.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-appearance: none;
    opacity: .4;
    transition: 0.2s opacity;

    &--active {
      background-image: url("../assets/sound-off.svg");
    }
    &--inverted {
      color: #fff;
      background-image: url("../assets/sound-on-inv.svg");

      &.mutebtn--active {
        background-image: url("../assets/sound-off-inv.svg");
      }
    }
    &:active,
    &:focus {
      outline: none;
    }
    span {
      display: none;
    }
    @include bp-s() {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .no-ios &:hover,
  &.active {
    .mutebtn {
      opacity: 1;
    }
  }
}
</style>
