<template>
  <div class="audioplayer" :class="{ playing: playing }">
    <button @click="togglePlay" class="audioplayer__button">
      <span class="sr-only">{{ buttonText }}</span>
    </button>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";

export default {
  name: "AudioPlayer",
  props: ["src"],
  data: () => {
    return {
      sound: null,
      playing: false,
    };
  },
  components: {},
  mounted() {},
  computed: {
    buttonText() {
      if (this.playing) return "Pause";
      return "Play";
    },
  },
  methods: {
    initAudio() {
      this.sound = new Howl({
        src: [this.src],
        autoplay: false,
      });
      this.sound.on("load", () => {
        this.sound.play();
      });
      this.sound.on("pause", () => {
        this.playing = false;
      });
      this.sound.on("play", () => {
        this.playing = true;
      });
      this.sound.on("end", () => {
        this.playing = false;
      });
    },
    togglePlay() {
      if (!this.sound) this.initAudio();
      if (this.playing) {
        this.sound.pause();
      } else {
        this.sound.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/helpers";

.audioplayer {
  border: 1px solid #000;
  background: #fff;
  height: 0;
  padding-bottom: 25%;

  &__button {
    --webkit-appearance: none;
    appearance: none;
    padding: 0;
    margin: 0;
    width: 1.5rem;
    height: 1.5rem;
    background: #fff;
    background-image: url("../assets/icon-play.svg");
    background-size: 100% 100%;
    background-position: center;
    box-sizing: border-box;
    // border: 1px solid #000;
    // border-radius: 99%;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -.75rem;
    margin-top: -.75rem;

    &:focus,
    &:active {
      outline: none;
    }
    .playing & {
      background-image: url("../assets/icon-pause.svg");
    }
  }

  @include bp-s() {
    padding-bottom: 60%;
  }
}
</style>
