<template>
  <header
    :class="{
      header: true,
      scrolled: isScrolled,
      nav: showNav,
      mobileshow: showMobile,
    }"
  >
    <div class="header__top">
      <h1>
        <router-link to="/" :class="{ active: isHome }"
          >Archives du futur</router-link
        >
      </h1>
      <div class="header__subtitle">
        <router-link to="/about" :class="{ active: !isHome }"
          >Silvie & Chérif Defraoui</router-link
        >
      </div>
      <ul class="header__lang">
        <li v-for="(lang, i) in filteredLangs" :key="`Lang${i}`">
          <a href="#" @click.prevent="setLang(lang)">{{ lang.substr(0, 1) }}</a>
        </li>
      </ul>
    </div>
    <scrollactive
      class="header__nav"
      :class="{ 'header__nav--visible': showNav }"
      :highlightFirstItem="true"
      :clickToScroll="false"
    >
      <ul>
        <li v-for="page in aboutData" :key="page.id">
          <router-link
            :to="'/about#' + page.slug"
            v-scroll-to="{ el: '#' + page.slug, offset: getScrollOffset() }"
            class="scrollactive-item"
            >{{ page.title }}</router-link
          >
        </li>
      </ul>
    </scrollactive>
    <div class="mobile-bar">
      <button class="mobile-bar__menubtn" @click="showMobile = !showMobile">
        <span>Menu</span>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data: () => {
    return {
      isScrolled: false,
      cycleData: null,
      showMobile: false,
      scrollOffset: -150,
    };
  },
  mounted() {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 50) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    });
  },
  watch: {
    $route: function (to, from) {
      this.showMobile = false;
    },
  },
  computed: {
    showNav() {
      return this.$route.name == "about";
    },
    aboutData() {
      return this.$store.state.aboutData;
    },
    langs() {
      return this.$store.state.langs;
    },
    filteredLangs() {
      return this.langs.filter((lang) => {
        return lang != this.$i18n.locale;
      });
    },
    isHome() {
      return (
        this.$route.name == "overview-detail" || this.$route.name == "overview"
      );
    },
  },
  methods: {
    setLang(lang) {
      let _lang = lang;
      this.$store.dispatch("changeLanguage", {
        lang: _lang,
        i18n: this.$i18n,
      });
    },
    getScrollOffset() {
      let offset = 10;
      if (window.innerWidth > 800) {
        offset = Math.round(window.innerWidth / 95) * -1;
      } else {
      }
      return offset;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/helpers";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  z-index: 500;
}

.header__top {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #fff;
  // padding: 0 var(--gutter);
  box-sizing: border-box;
  background-color: #fff;
  transition: 1s border-color;

  .scrolled &,
  .nav & {
    border-color: #000;
  }
  .router-link-exact-active,
  .active {
    font-style: italic;
  }
}

h1,
.header__subtitle {
  display: block;
  width: 50%;
  text-align: center;
  line-height: 3rem;
  white-space: nowrap;
}
.header__subtitle {
  width: 30%;
  // padding-right: 8%;
  // margin-left: 5%;
  box-sizing: border-box;
  z-index: 2;
}
h1 {
  text-transform: uppercase;
  // font-style: italic;
  width: 55%;
}

.header__lang {
  position: absolute;
  right: var(--gutter);
  top: 0;
  // width: 8%;
  text-align: right;
  display: flex;

  li {
    display: block;
    line-height: 1rem;
    padding-top: 1rem;
    text-transform: uppercase;

    a {
      display: inline-block;
      width: .8rem;
      text-align: center;
      height: 1rem;
    }

    &::after {
      content: "/";
      display: inline-block;
      margin: 0 3px;
    }
    &:last-child::after {
      content: "";
      margin: 0;
    }
  }
}

.header__nav {
  position: absolute;
  top: 3rem;
  width: 100%;
  z-index: 0;
  height: 2.5rem;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-30%);
  background-image: linear-gradient(rgba(#fff, 1) 40%, rgba(#fff, 0) 100%);
  transition: 0.5s transform $easeIn, 0.5s opacity $easeIn;

  &--visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    transition: 0.5s transform $easeOut, 0.5s opacity $easeOut;
  }

  ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
      font-size: 0.7rem;
      margin: 0 0.3rem;
      line-height: 2.3;
      white-space: nowrap;
      // word-spacing: -.15rem;

      .is-active {
        font-style: italic !important;
      }
      .ios & a:hover {
        font-style: inherit;
      }
    }
  }
}
.mobile-bar {
  display: none;
}

@include bp-s() {
  .header {
    top: auto;
    bottom: 0;
    height: auto;
    padding-bottom: 2rem;
    max-height: 3rem;
    overflow: hidden;
    padding-top: 1rem;
    font-size: 1rem;
    transition: 0.5s max-height;

    &.mobileshow {
      max-height: 340px;
      // padding-bottom: 2rem;
    }
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(rgba(#fff, 0), #fff);
    }
  }
  .header__top {
    position: static;
    display: block;
    text-align: center;
    padding: 0;
    z-index: 1;

    .scrolled &,
    .nav & {
      border-bottom: none;
      // border-color: #fff;
      transition: 0s border-color;
    }
    h1 {
      text-align: center;
      width: 100%;
      border-bottom: 1px solid #000;
      line-height: 1.2;
      padding: 0.5rem 0;
    }
  }
  .header__subtitle {
    width: 100%;
    padding: 0;
    padding-top: 0.5rem;
    line-height: 1.2;
  }
  .header__lang {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: auto;
    text-align: center;
    top: auto;
    right: auto;
    z-index: 1;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s opacity;

    .mobileshow & {
      opacity: 1;
      pointer-events: auto;
    }

    li {
      line-height: 2rem;

      &::after {
        margin: 0 0.5rem;
      }
    }
  }
  .header__nav {
    position: relative;
    background: #fff;
    height: auto;
    top: auto;
    padding: 0 0 0.5rem 0;

    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    transition: 0s transform, 0s opacity;

    ul {
      display: block;
    }
    ul li {
      font-size: 1rem;
      display: block;
      text-align: center;
      margin: 0;
      line-height: 1.2;

      &:first-child {
        display: none;
      }
    }
  }
  .mobile-bar {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    background: #fff;
  }
  .mobile-bar__menubtn {
    position: absolute;
    left: var(--gutter);
    bottom: 0.2rem;
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    background-image: url("../assets/icon-burger.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;

    &:active,
    &:focus {
      outline: none;
      opacity: 0.5;
    }
    span {
      display: none;
    }
  }
}
@media screen and (max-height: 300px) {
  .header__top,
  .header__top h1,
  .header__nav ul li {
    font-size: 0.7rem;
  }
}
</style>
