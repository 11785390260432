<template>
  <div id="app" :class="{ 'ios': ios, 'no-ios': !ios }">
    <Header />
    <Intro @kill="showIntro = false" v-if="showIntro" />
    <router-view />
  </div>
</template>

<script>
import Intro from "@/components/Intro";
import Header from "@/components/Header";

export default {
  name: "Home",
  components: {
    Header,
    Intro,
  },
  data: () => {
    return {
      showIntro: true
    };
  },
  watch: {
    activeLang() {
      this.$store.commit("setApiUrl");
      this.$store.dispatch("loadInitialData");
    },
  },
  mounted() {
    this.checkLocalLang();
    const isIos = this.checkIos();
    this.$store.commit("setIos", isIos);
    this.$store.commit("setApiUrl");
    this.$store.dispatch("loadInitialData");
  },
  computed: {
    activeLang() {
      return this.$store.state.activeLang;
    },
    ios() {
      return this.$store.state.ios;
    },
  },
  methods: {
    checkLocalLang() {
      let localLang = window.localStorage.getItem("ADF_LANG");
      if (localLang == "fr" || localLang == "en") {
        this.$store.dispatch("changeLanguage", {
          lang: localLang,
          i18n: this.$i18n,
        });
      }
    },
    checkIos() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    }
  },
};
</script>

