<template>
  <transition name="intro-fade">
    <div class="intro">
      <h1>
        <span class="mobile-headline"
          >ARCHIVES DU FUTUR<br />Silvie & Chéerif Defraoui</span
        >Catalogue raisonné
      </h1>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Intro",
  data: () => {
    return {};
  },
  mounted() {
    // document.body.style.overflow = "hidden";

    setTimeout(() => {
      this.$emit("kill");
    }, 1500);
  },
  beforeDestroy() {
    // document.body.style.overflow = "auto";
  },
};
</script>

<style scoped lang="scss">
@import "../scss/helpers";

.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .mobile-headline {
    display: none;

    @include bp-s() {
      display: block;
      margin-bottom: .5rem;
    }
  }
}

.intro-fade-enter-active,
.intro-fade-leave-active {
  transition: 2s opacity;
}
.intro-fade-enter,
.intro-fade-leave-to {
  opacity: 0;
}
</style>
