<template>
  <div class="overview">
    <OverviewSphere :visible="true" />
    <h1 class="headline-mobile">Archives du Futur</h1>
    <ul class="overview__list"
        v-if="workCycles">
      <OverviewItem v-for="(cycle, index) in filteredCycles"
                    v-bind:key="cycle.id"
                    :item="cycle"
                    :index="index"
                    :zindex="filteredCycles.length - index"
                    :isOpen="cycle.slug === openItemSlug"
                    :openItemSlug="openItemSlug" />
      <li class="overview__teaseritem"
          v-if="!activeFilter">
        {{ $t("tobecontinued") }}
      </li>
      <li class="overview__teaseritem"
          v-if="!activeFilter">
        <a href="https://vimeo.com/906004943?share=copy"
           target="_blank">{{ $t("expositionLink") }}</a>
      </li>
    </ul>
    <OverviewFilter v-if="!openItemSlug" />
    <Digression v-if="!openItemSlug" />
  </div>
</template>

<script>
import OverviewItem from "@/components/OverviewItem";
import OverviewFilter from "@/components/OverviewFilter";
import OverviewSphere from "@/components/OverviewSphere";
import Digression from "@/components/Digression";

export default {
  name: "Overview",
  data: () => {
    return {
      openItemSlug: null,
    };
  },
  watch: {
    $route(to, from) {
      if (to.params.slug) {
        this.openItemSlug = to.params.slug;
      } else {
        this.openItemSlug = null;
      }
    },
  },
  mounted() {
    if (this.$route.params.slug) {
      this.openItemSlug = this.$route.params.slug;
    }
  },
  components: {
    OverviewItem,
    OverviewFilter,
    OverviewSphere,
    Digression,
  },
  computed: {
    workCycles() {
      return this.$store.state.workCycles;
    },
    filteredCycles() {
      return this.$store.getters.filteredCycles;
    },
    activeFilter() {
      return this.$store.state.activeFilter;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "../scss/helpers";

.overview {
  box-sizing: border-box;
  padding-bottom: 1rem;
}

.overview__list {
  margin: 0;
  padding: 0;
}

.overview__teaseritem {
  padding: 0 var(--gutter);
  position: relative;
  margin-top: 3.5rem;
}

.headline-mobile {
  display: none;
}

@include bp-s() {
  .overview {
    padding-top: 3rem;
    padding-bottom: 0;

    &__list {
      margin-bottom: 4rem !important;
    }
  }

  .overview__teaseritem {
    margin-top: 2rem;
  }

  .headline-mobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-transform: uppercase;
    line-height: 2.2rem;
    background-color: #fff;
    border-bottom: 1px solid #000;
    z-index: 4;
    text-align: center;
    font-style: italic;
  }
}</style>
