<template>
  <div class="cycleinfo" v-if="artworks && artworks.length > 0">
    <a class="detail-link" href="#" @click.prevent="visible = !visible">
      <span v-if="!visible">{{ $t("showDetail") }}</span>
      <span v-if="visible">{{ $t("hideDetail") }}</span>
    </a>
    <ul class="cycleinfo__customlist" v-if="cycleData.detailsText && visible">
      <li
        v-for="details in cycleData.detailsText"
        :key="details.title"
        class="cycleinfo__detailstext"
      >
        <span class="title" v-if="details.title">{{ details.title }}</span>
        <p>{{ details.body }}</p>
      </li>
    </ul>
    <ul class="cycleinfo__list" v-if="visible">
      <li v-for="item in orderedArtworks" :key="item.id">
        <div class="content">
          {{ item.inventaryId }}<br />
          <span v-if="item.year">{{ item.title }}, {{ item.year }}</span>
          <span v-else>{{ item.title }} {{ $t("noDate") }}</span>
          <p>
            {{ item.technique }}<br />
            <ul class="cycleinfo__sizes" v-if="item.size.metric || item.size.variable">
              <li v-if="item.size.metric">{{ item.size.metric }}</li>
              <li v-if="item.size.imperial">{{ item.size.imperial }}</li>
            </ul>
            <span v-if="item.size.variable" class="span-block">{{ $t("dimensionsVariable") }}</span>
            <span v-if="item.duration">{{ item.duration }}<br></span>
            <span class="span-block" v-if="item.exhibitions"
              >{{ $t("exhibitions") }}: {{ item.exhibitions }}</span
            >
            <span class="span-block" v-if="item.collection"
              >{{ $t("collections") }}: {{ item.collection }}</span
            >
            <span class="span-block" v-if="item.location"
              >{{ item.location }}</span
            >
          </p>
        </div>
        <img
          v-if="item.asset && item.asset.type == 'image'"
          class="cycleinfo__preview"
          :alt="item.title"
          :src="item.asset.thb"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import compareVersions from "compare-versions";

export default {
  name: "CycleInformation",
  props: ["item", "cycleData"],
  data: () => {
    return {
      visible: false,
    };
  },
  components: {},
  mounted() {},
  computed: {
    artworks() {
      return this.cycleData.artworks;
    },
    orderedArtworks() {
      return this.cycleData.artworks.sort((a, b) => {
        return compareVersions(a.inventaryId, b.inventaryId);
      });
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "../scss/helpers";

.cycleinfo {
  // margin-top: -6rem;
  // margin-bottom: 10rem;
  min-height: 3rem;
  position: relative;
  // padding: 0 var(--gutter);

  @include bp-s() {
    margin-top: -1.5rem;
    // margin-bottom: 2rem;
  }
}
.detail-link {
  font-size: 0.7rem;
  position: absolute;
  top: 0;
  right: var(--gutter);
  text-decoration: underline;

  span {
    white-space: nowrap;
  }
}
.cycleinfo__list,
.cycleinfo__customlist {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 3rem;

  > li {
    display: block;
    width: 33.3%;
    margin: 1rem 0;
    font-size: 0.7rem;
    padding: 0 var(--gutter);
    position: relative;

    &:hover {
      .cycleinfo__preview {
        opacity: 1;
      }
    }
  }
  p {
    margin-top: 0.5rem;
  }
  .content {
    position: relative;
    z-index: 1;
  }
}
.cycleinfo__customlist + .cycleinfo__list {
  padding-top: 1rem;
}
.cycleinfo__detailstext {
  .title {
    display: block;
    white-space: pre-wrap;
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0;
    white-space: pre-wrap;
  }
}
.cycleinfo__size {
  display: block;
}
.cycleinfo__sizes {
  display: flex;
  flex-wrap: wrap;

  li {
    display: block;
    width: auto;
    margin: 0;

    &::after {
      content: " /";
      margin-right: 0.25rem;
    }
    &:last-child::after {
      display: none;
    }
  }
}
.cycleinfo__preview {
  position: absolute;
  top: 50%;
  left: 32.5%;
  width: 35%;
  height: auto;
  transform: translateY(-50%);
  object-fit: contain;
  opacity: 0;
  pointer-events: none;
  transition: 1s opacity;
}

.span-block {
  display: block;
}

@include bp-s() {
  .cycleinfo {
    margin-top: -1.5rem;
  }
  .cycleinfo__list,
  .cycleinfo__customlist {
    max-width: 500px;

    > li {
      width: 100%;
      margin: 0.75rem 0;
      line-height: 1.2;
    }
  }
  .cycleinfo__preview {
    width: 40%;
    left: 30%;
  }
}
</style>
