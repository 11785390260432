<template>
  <div class="home">
    Lorem Ipsum<br><i>Lorem Ipsum</i><br><em>Lorem Ipsum</em>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data: () => {
    return {
    }
  },
  mounted() {
  },
  components: {
  }
}
</script>

<style lang="scss">

</style>
