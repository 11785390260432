import './scss/_normalize.scss'
import './scss/_fonts.scss'
import './scss/_global.scss'

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueScrollTo from 'vue-scrollto';
import VueScrollactive from "vue-scrollactive";
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import locale from './locale'

/* axios interceptor to change request url in craft live preview */
axios.interceptors.request.use(function (config) {
  let params = new URLSearchParams(document.location.search.substring(1));
  let token = params.get('token');
  if(token) {
    config.url = config.url + '?token=' + token;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
/* */

Vue.use(VueScrollTo, {
  duration: 800,
  container: 'html'
});

Vue.use(VueScrollactive);

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'de',
  messages: locale
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
