<template>
  <li
    v-bind:class="{
      listitem: true,
      'is-open': isOpen,
      empty: !item.hasLayout,
      stuck: isSticky,
    }"
  >
    <router-link
      :to="headUrl"
      class="head"
      ref="head"
      @click.prevent="$emit('navigate', headUrl)"
      @mouseover.native="updateSphereTexture"
      @mouseout.native="hideSphere"
    >
      <i class="observer" ref="observer"></i>
      <span class="head__num">{{ item.inventaryId }}</span>
      <span class="head__title">{{ item.title }}</span>
      <span class="head__year">{{ item.year }}</span>
      <div class="head__meta" v-bind:class="{ visible: isOpen }">
        <ul class="head__categories">
          <li v-for="cat in item.categories" :key="cat.id">{{ cat.title }}</li>
        </ul>
      </div>
    </router-link>
    <div class="loading" v-if="isOpen && !cycleData && !loadError">
      loading...
    </div>
    <div class="loading" v-if="isOpen && loadError">
      error: could not load data
    </div>
    <div class="layout-wrap" v-if="isOpen && cycleData">
      <CycleLayout :item="item" :cycleData="cycleData" />
      <CycleInformation :item="item" :cycleData="cycleData" />
    </div>
  </li>
</template>

<script>
import axios from "axios";
import CycleLayout from "./CycleLayout";
import CycleInformation from "./CycleInformation";

export default {
  name: "OverviewItem",
  props: ["item", "index", "isOpen", "zindex", "openItemSlug"],
  components: {
    CycleLayout,
    CycleInformation,
  },
  data: () => {
    return {
      cycleData: null,
      loadError: false,
      resizeListener: null,
      isSticky: false,
      layoutHeight: 0
    };
  },
  mounted() {
    if (this.isOpen) {
      this.loadData();
      this.openLayout();
    }
  },
  watch: {
    isOpen(to, from) {
      if (to) {
        this.$nextTick(() => {
          this.openLayout();
        });
      }
      if (to && !this.cycleData) {
        this.loadData();
      }
      if (from) {
        if(!this.openItemSlug) {
          setTimeout(() => {
            this.$scrollTo(this.$el, 300, {
              offset: -80,
            });
          }, 20);
        }
        this.destroy();
      }
    },
    activeLang() {
      if (this.isOpen) {
        this.loadData();
      }
    },
  },
  computed: {
    headUrl() {
      if (this.isOpen || !this.item.hasLayout) {
        return "/";
      }
      return "/artworks/" + this.item.slug;
    },
    categoriesFormatted() {
      let catText = "";
      this.item.categories.forEach((cat) => {
        catText += cat.title + ", ";
      });
      return catText;
    },
    apiUrl() {
      return this.$store.state.apiUrl;
    },
    activeLang() {
      return this.$store.state.activeLang;
    },
    ios() {
      return this.$store.state.ios;
    },
  },
  methods: {
    loadData() {
      axios
        .get(this.apiUrl + "artworks/" + this.item.slug)
        .then((data) => {
          this.cycleData = data.data;
          // this.openLayout();
        })
        .catch((error) => {
          this.loadError = true;
          console.log(error);
        });
    },
    updateSphereTexture() {
      if (!this.item.sphereImage) return;
      if (this.ios) return;
      if (this.isOpen) return;

      this.$store.commit("setSphereTexture", this.item.sphereImage.url);
      this.$store.commit("setSphereVisible", true);
    },
    hideSphere() {
      this.$store.commit("setSphereVisible", false);
    },
    openLayout() {
      setTimeout(() => {
        this.hideSphere();
      }, 100);
      setTimeout(() => {
        this.$scrollTo(this.$el, 500, {
          offset: this.getScrollOffset(),
        });
      }, 200);
      this.initObserver();
    },
    initObserver() {
      if (!this.observer) {
        this.observer = new IntersectionObserver(
          ([e]) => {
            this.isSticky = !e.isIntersecting;
          },
          { threshold: [1] }
        );
      }
      this.observer.observe(this.$refs.observer);
    },
    destroy() {
      this.observer.disconnect();
    },
    getScrollOffset() {
      if(window.innerWidth < 768) {
        return -40;
      }
      return (window.innerWidth / 22.5) * -1;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/helpers";

.listitem {
  position: relative;
  display: block;
}
.observer {
  display: block;
  position: absolute;
  top: calc(-3.48rem - 1px);
  left: 0;
  width: 1px;
  height: 1px;
  background: #fff;
}
.head {
  position: sticky;
  top: 3.48rem;
  left: 0;
  z-index: 2;
  display: flex;
  font-size: 1rem;
  // line-height: 1.16;
  height: 1.5rem;
  margin-top: -0.35rem;
  padding: 0 var(--gutter);
  z-index: 3;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1.5rem;
    background: linear-gradient(#fff 20%, rgba(#fff, 0));
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 0.5rem;
    background: #fff;
    position: absolute;
    top: -0.5rem;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }

  .is-open & {
    z-index: 2;
    font-style: normal !important;
  }
  .is-open.stuck & {
    background: #fff;

    &::before {
      opacity: 1;
    }
    &::after {
      opacity: 1;
    }
  }

  &:hover {
    color: inherit;

    .head__year {
      opacity: 1;
    }
    /*.head__title {
      font-style: italic;
    }*/
  }
  .ios &:hover {
    .head__year {
      opacity: 0;
    }
  }
  &:focus,
  &:active {
    outline: none;
  }

  &__num {
    width: 2.4rem;
    flex-grow: 0;
    flex-shrink: 0;

    .empty & {
      color: #888;
    }
  }
  &__title {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 2;

    .is-open & {
      overflow: visible;
      height: auto;
      white-space: normal;
      text-overflow: clip;
    }
  }
  &__year {
    width: 5.5rem;
    text-align: left;
    flex-shrink: 0;
    white-space: nowrap;
    opacity: 0;
    transition: 0.2s opacity;

    .is-open & {
      opacity: 1 !important;
    }
  }
  &__meta {
    box-sizing: border-box;
    width: calc(50% - 5.5rem);
    padding-left: 1rem;
    font-size: 1rem;
    flex-shrink: 0;
    opacity: 0;
    transition: 0.5s opacity;
    text-align: right;
    position: relative;
    font-style: normal !important;
    z-index: 2;

    .is-open & {
      opacity: 1;
    }
  }
  &__categories {
    font-size: 0.7rem;
    position: absolute;
    top: 0.05rem;
    left: 1rem;
    text-align: right;
    width: 100%;
    margin-top: 0.2rem;
    padding-right: var(--gutter);

    li {
      display: inline-block;
      text-overflow: ellipsis;
      line-height: 1.5;
      line-height: 0.9;
      margin-left: 0.25rem;
      // margin-top: .23rem;

      &::after {
        content: " / ";
        // margin-right: 0.25rem;
      }
      &:last-child::after {
        content: "";
        margin-right: 0;
      }
    }
  }
}

.layout-wrap {
  padding-bottom: 12rem;

  @include bp-s() {
    padding-bottom: 6rem;
  }
}

@include bp-s() {
  .head {
    top: 2.5rem;
    line-height: 1.2;

    .is-open & {
      height: auto;
    }
  }
  .observer {
    top: calc(-2.5rem - 1px);
  }
  .head__title {
    // white-space: normal;
  }
  .head__year,
  .head__meta {
    display: none;
  }
}

.loading {
  padding: 1rem var(--gutter) 2rem var(--gutter);
  font-size: 0.7rem;
}

.cycle-fade-enter-active,
.cycle-fade-leave-active {
  transition: transform 2s;
  transform-origin: 0 0;
}
.cycle-fade-enter, .cycle-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
}
</style>
