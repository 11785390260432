<template>
  <div :class="{ sphere: true, visible: visible, hidden: (empty || !sphereVisible) }">
    <canvas class="sphere__canvas" ref="canvas"></canvas>
  </div>
</template>

<script>
import * as THREE from "three";

export default {
  name: "OverviewSphere",
  props: ["visible"],
  data: () => {
    return {
      destroyed: false,
      material: null,
      matTexture: null,
      loader: null,
      empty: true,
    };
  },
  watch: {
    sphereTexture(to, from) {
      // this.cube.material.dispose();
      if (this.matTexture) this.matTexture.dispose();
      this.empty = true;
      let tempTex = this.loader.load(to, () => {
        this.matTexture = tempTex;
        this.matTexture.minFilter = THREE.LinearFilter;
        this.matTexture.generateMipmaps = false;
        this.material.map = this.matTexture;
        this.material.opacity = 1;
        this.material.needsUpdate = true;
        this.render();
        this.empty = false;
      });
    },
    visible(to, from) {
      if (to) {
        this.$nextTick(() => {
          // this.animate();
        });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    this.scene = null;
    this.camera = null;
    this.destroyed = true;
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.initSphere();
  },
  computed: {
    sphereTexture() {
      return this.$store.state.sphereTexture;
    },
    sphereVisible() {
      return this.$store.state.sphereVisible;
    },
  },
  methods: {
    initSphere() {
      if (!this.$refs.canvas) return;
      this.loader = new THREE.TextureLoader();
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color("white");
      this.camera = new THREE.PerspectiveCamera(18, 1, 1, 1000);
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.$refs.canvas,
        alpha: false,
        antialias: true,
      });
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      var geometry = new THREE.SphereGeometry(1, 25, 25);
      this.material = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        specular: 0x333333,
        map: this.matTexture,
      });
      this.material.transparent = true;
      this.material.opacity = 0;
      this.cube = new THREE.Mesh(geometry, this.material);
      this.cube.rotation.y = -1.575;
      this.cube.material = this.material;
      this.scene.add(this.cube);

      const light = new THREE.PointLight(0xffffff, .7);
      light.position.set(10, 10, 32);
      this.scene.add(light);
      const lightAmbient = new THREE.AmbientLight(0xaaaaaa, 1);
      this.scene.add(lightAmbient);
      const helper = new THREE.PointLightHelper(light);
      this.scene.add(helper);

      this.camera.position.z = 12;

      this.handleResize();

      this.render();
      // this.animate();
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    animate() {
      // this.cube.rotation.x += 0.005;
      // this.cube.rotation.y += 0.005;
      if (this.sphereVisible && this.cube.material.opacity < 0.8) {
        // this.cube.material.opacity += 0.02;
      } else if (!this.sphereVisible && this.cube.material.opacity > 0) {
        // this.cube.material.opacity -= 0.02;
      }

      this.renderer.render(this.scene, this.camera);

      window.requestAnimationFrame(() => {
        if (!this.visible || this.destroyed) return;
        // this.animate();
      });
    },
    handleResize() {
      if (window.innerWidth < 800) {
        this.camera.position.z = 20;
      } else {
        this.camera.position.z = 12;
      }

      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.render();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../scss/helpers";

.sphere {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: 0.5s opacity;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include bp-s() {
    display: none;
  }
}
</style>
